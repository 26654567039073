<template>
  <div id="privacy" style="background-color:#f4f4f4 !important">
    <br />
    <br />
    <div class="row">
      <div class="container mb-5"  style="background-color:white !important;border-radius:25px">
        <div class="row no-gutters">
          <div class="col-12 ml-2" style="text-align:center">
            <div class="text-center">
              <img
                id=""
                class=""
                src="https://prod-media-as1.s3.ap-southeast-1.amazonaws.com/miscellaneous/Matchday_Logo-01_jlclfy.jpg"
                style="height: 100px; width: 100px; border-radius: 25%"
              />
              <br />
            </div>
          </div>
          <div class="col-12">
            <span style="font-size: 16px" class="ml-n3">
              &nbsp; &nbsp;<b> Privacy Policy MATCHDAY - แอพจองสนาม</b></span
            >
          </div>
          <div class="col-12 mx-3">
            <div class="divider mb-3 mt-3"></div>
          </div>

          <div class="col-12">
            <div class="detail">
              <p
                class="c"
                style="margin-left: 20px; margin-right: 20px; font-size: 12px"
              >
                ปรับปรุงครั้งล่าสุดวันที่ 9 พฤษภาคม 2563<br />
              </p>
              <b>
                <div style="font-size: 16px" class="ml-3">
                  นโยบายคุ้มครองข้อมูลส่วนบุคคลของแมทช์เดย์
                </div></b
              >
              <p class="c" style="margin-left: 20px; margin-right: 20px">
                <br />
                <span style="font-size: 14px">
                  <b> 1. คำนิยาม – Definition</b> <br />

                  - “นโยบาย” หมายถึง นโยบายคุ้มครองข้อมูลส่วนบุคคล
                  และส่วนที่แก้ไขเพิ่มเติม
                  <br />- “แมทช์เดย์” หรือ “เรา” หมายถึง บริษัท แมทช์เดย์ ฮับ
                  จำกัด และบริษัทลูก บริษัทที่เกี่ยวข้องกัน
                  และบริษัทที่อยู่ภายใต้การควบคุมเดียวกัน <br />- แพลตฟอร์ม”
                  หมายถึง แอปพลิเคชัน เว็บไซต์ของแมทช์เดย์
                  รวมถึงแอปพลิเคชันบนโทรศัพท์มือถือ
                  และเว็บไซต์ที่ดำเนินการและให้บริการโดยแมทช์เดย์ <br />-
                  “บริการ” หมายถึง ผลิตภัณฑ์ ฟีเจอร์ (feature) และบริการใดๆ
                  ทั้งหมดทั่วโลก โดยเรา
                  รวมถึงโดยไม่จำกัดเฉพาะกีฬาใดกีฬาหนึ่งโดยเฉพาะ <br />- “คุณ”
                  หมายถึง ลูกค้า ผู้รับบริการ ตัวแทน ผู้ค้า ผู้จัดหา และ
                  ผู้ให้บริการสถานบริการด้านกีฬา <br />- “สถานบริการด้าน”
                  หมายถึง บุคคล และ/หรือ นิติบุคคลทั้งแสวงหารายได้
                  และไม่แสวงหารายได้ที่ประกอบกิจการการให้บริการ
                  หรือมีหน่วยบริการการเช่าพื้นที่สำหรับเล่นกีฬา
                  รวมถึงไม่จำกัดเฉพาะกีฬาใดกีฬาหนึ่งโดยเฉพาะ
                  และทำสัญญาเข้าใช้บริการกับเรา <br />- “พื้นที่” หมายถึง
                  พื้นที่ภายในสนามที่มีการแบ่งออกเป็นหลายๆ
                  พื้นที่เพื่อให้บริการกีฬารวมถึงไม่จำกัดเฉพาะกีฬาใดกีฬาหนึ่งโดยเฉพาะ
                  <br />- “ข้อมูลส่วนบุคคล” หมายถึง ข้อมูลของคุณ
                  ที่สามารถระบุตัวตนของคุณได้ รวมถึงโดยไม่จำกัดเฉพาะ ชื่อ
                  นามสกุล หมายเลขโทรศัพท์ เพศ อายุ ที่อยู่ไปรษณีย์อิเล็กทรอนิกส์
                  (email address) ภาพถ่ายของคุณ
                  รวมถึงปฏิสัมพันธ์การใช้งานภายในแพลตฟอร์ม
                </span>
              </p>

              

              <p class="c" style="margin-left: 20px; margin-right: 20px">
                <span style="font-size: 14px">
                  <b> 2. ข้อตกลงทั่วไป – General Agreement</b><br />
                  เนื่องจาก แมทช์เดย์
                  ตระหนักถึงความสำคัญของข้อมูลส่วนบุคคลที่คุณได้มอบให้แก่บริษัทด้วยความไว้วางใจเราจึงจะใช้วิธีการที่เหมาะสมเพื่อจัดการ
                  คุ้มครอง และดำเนินการกับข้อมูลส่วนบุคคลของคุณ
                  นโยบายนี้ได้รับการออกแบบมาเพื่อช่วยเหลือคุณให้เข้าใจถึงวิธีการของบริษัทที่ใช้ในการจัดเก็บ
                  การนำไปใช้ การเปิดเผย และ/หรือ การดำเนินการกับข้อมูลส่วนบุคคล
                  เว้นแต่กรณีที่มีกฎหมายจำกัดไว้
                  คุณตกลงให้แมทช์เดย์ดำเนินการกับข้อมูลส่วนบุคคลตามวิธีการและวัตถุประสงค์ที่กำหนดไว้ในนโยบายนี้
                </span>
              </p>

              <p class="c" style="margin-left: 20px; margin-right: 20px">
                <span style="font-size: 14px">
                  <b>3. การเก็บรวบรวมข้อมูลส่วนบุคคล – Data Collection</b><br />
                  ด้วยความยินยอมล่วงหน้าของคุณ
                  เราเก็บรวบรวมข้อมูลส่วนบุคคลเกี่ยวกับคุณตามวิธีการด้านล่างนี้
                  โดยเราสามารถนำข้อมูลส่วนบุคคลที่เก็บรวบรวมนี้เข้ากับข้อมูลส่วนบุคคลอื่นที่อยู่ในความครอบครองของเรา
                  <br />3.1 กรณีที่คุณให้ข้อมูลส่วนบุคคลของคุณกับเรา
                  เราเก็บรวบรวมข้อมูลส่วนบุคคลของคุณเมื่อคุณให้เรามาอย่างสมัครใจ
                  เช่น คุณอาจให้ข้อมูลส่วนบุคคลของคุณกับเรา เมื่อคุณ:
                  <br />&nbsp;&nbsp;&nbsp;• กรอกรายละเอียดหน้าประวัติผู้ใช้
                  หรือใบสมัคร <br />&nbsp;&nbsp;&nbsp;•
                  ให้ข้อมูลเพื่อใช้ในการประเมินคุณสมบัติของคุณในการให้บริการในฐานะพาร์ทเนอร์
                  และ/หรือลูกค้าของแมทช์เดย์ <br />&nbsp;&nbsp;&nbsp;•
                  ติดต่อกับพนักงานของเรา และ <br />&nbsp;&nbsp;&nbsp;•
                  ติดต่อกับหน้าสื่อสังคมออนไลน์ (social media) และเว็ปไซต์ของเรา
                  <br />3.2 เมื่อใช้บริการของเรา
                  <br />ข้อมูลส่วนบุคคลอาจถูกเก็บรวบรวมโดยอัตโนมัติผ่านการทำงานตามปกติและปฏิสัมพันธ์ภายในแพลตฟอร์ม
                  และบริการของเรา
                  เราจะขอความยินยอมจากคุณก่อนหรือตามที่กฎหมายกำหนด ตัวอย่างเช่น
                  <br />&nbsp;&nbsp;&nbsp;•
                  ตำแหน่งของคุณที่เก็บจากโทรศัพท์มือถือของคุณ
                  (เพื่อประโยชน์ต่อการทำการตลาดของแมทช์เดย์
                  และนำการนำเสนอสถานที่เล่นกีฬาใกล้เคียงเพื่อประโยชน์สูงสุดในการเล่นกีฬาของคุณ)
                  <br />&nbsp;&nbsp;&nbsp;• ข้อมูลธุรกรรมการจอง (เช่น
                  กีฬาที่ใช้บริการ วันเวลาที่ใช้บริการ
                  และรายละเอียดอื่นๆที่เกี่ยวข้องกับการใช้บริการสถานที่เพื่อการเล่นกีฬา)
                  <br />&nbsp;&nbsp;&nbsp;•
                  ข้อมูลเกี่ยวกับการตอบสนองของคุณกับแอปฯ เว็บไซต์
                  หรือแพลตฟอร์มบริการของเรา (เช่น การใช้ฟีเจอร์ (feature)
                  หรือการรับชมเนื้อหา) <br />&nbsp;&nbsp;&nbsp;• ข้อมูลอุปกรณ์
                  (เช่น ระบบปฏิบัติการของอุปกรณ์ที่ของคุณ)
                  <br />&nbsp;&nbsp;&nbsp;• ข้อมูลส่วนบุคคลที่คุณกรอกผ่านฟีเจอร์
                  (feature) สื่อสารภายในแพลตฟอร์ม (เช่น ชื่อ นามสกุล เพศ วันเกิด
                  เบอร์โทรศัพท์มือถือ อีเมล และภาพถ่ายของคุณ) และ
                  <br />&nbsp;&nbsp;&nbsp;•
                  ข้อมูลที่เราเก็บรวบรวมโดยอัตโนมัติเมื่อคุณใช้แพลตฟอร์มของเราและ/หรือบริการออนไลน์อาจถูกรวบรวมผ่าน
                  คุกกี้และเทคโนโลยีที่คล้ายคลึงกัน <br />&nbsp;&nbsp;&nbsp;•
                  ข้อมูลส่วนบุคคลเมื่อเราเชื่อมโยงบริการดิจิตอลของเรากับบริการของบุคคลที่สาม
                  เช่น เมื่อเรารวมบริการของเรากับเครือข่ายสังคมเช่น Facebook
                  <br />3.3 จากแหล่งอื่น
                  <br />เราอาจเก็บรวบรวมข้อมูลส่วนบุคคลของคุณจากแหล่งข้อมูลที่มีการเปิดเผยโดยทั่วไป
                  หรือจากแหล่งข้อมูลอื่นๆ เช่น <br />&nbsp;&nbsp;&nbsp;•
                  จากแหล่งที่มาสาธารณะ <br />&nbsp;&nbsp;&nbsp;•
                  แหล่งข้อมูลของทางราชการ
                  <br />นอกจากนี้คุณยินยอมและยอมรับในเรื่องข้อมูลส่วนบุคคลของผู้เยาว์
                  ดังนี้ <br />ข้อมูลส่วนบุคคลของผู้เยาว์
                  ในฐานะบิดามารดาหรือผู้ปกครองตามกฎหมาย
                  กรุณาอย่าอนุญาตให้ผู้เยาว์ภายใต้การดูแลของคุณให้ข้อมูลส่วนบุคคลแก่แมทช์เดย์
                  ในกรณีที่ได้ให้ข้อมูลส่วนบุคคลของผู้เยาว์ดังกล่าวแก่แมทช์เดย์
                  ให้ถือว่า
                  คุณยินยอมให้มีการประมวลผลข้อมูลส่วนบุคคลของผู้เยาว์นั้น
                  และยินยอมและตกลงที่จะผูกพันต่อนโยบายนี้และรับผิดชอบต่อการกระทำของผู้เยาว์
                </span>
              </p>

              

              <p class="c" style="margin-left: 20px; margin-right: 20px"></p>

              <p class="c" style="margin-left: 20px; margin-right: 20px">
                <span style="font-size: 14px">
                  <b> 4. การใช้ข้อมูลส่วนบุคคล - Use of Data </b><br />
                  แมทช์เดย์อาจใช้ รวบรวม
                  และประมวลผลข้อมูลส่วนบุคคลของคุณเพื่อให้บริการของเรากับคุณ
                  สนับสนุนและปรับปรุงบริการของเราเพื่อให้บริการที่เป็นส่วนตัวและเกี่ยวข้องมากขึ้น
                  โดยที่เราจะขอความยินยอมจากคุณก่อน ในวัตถุประสงค์ดังต่อไปนี้
                  (“วัตถุประสงค์”)
                  <br />4.1 ให้บริการและฟีเจอร์ส
                  <br />ข้อมูลส่วนบุคคลของคุณอาจถูกใช้เพื่อให้บริการ
                  ทำให้มีความเฉพาะตัว รักษา และพัฒนาผลิตภัณฑ์และบริการของเรา
                  รวมถึงการใช้ข้อมูลส่วนบุคคลของคุณ เพื่อ:
                  <br />&nbsp;&nbsp;&nbsp;• ให้บริการผ่านหน่วยธุรกิจต่างๆ ของเรา
                  <br />&nbsp;&nbsp;&nbsp;• ติดต่อคุณเพื่อการให้บริการ
                  <br />&nbsp;&nbsp;&nbsp;• สร้าง จัดการ
                  และทำให้บัญชีของคุณเป็นปัจจุบัน <br />&nbsp;&nbsp;&nbsp;•
                  ยืนยันตัวบุคคลของคุณ <br />&nbsp;&nbsp;&nbsp;•
                  ตรวจสอบการรับบริการของคุณ และประมวลผลการชำระเงิน
                  <br />&nbsp;&nbsp;&nbsp;• เสนอ รับ จัดให้
                  หรืออำนวยความสะดวกในบริการจากสถานบริการด้านกีฬาบนแพลตฟอร์ม
                  <br />&nbsp;&nbsp;&nbsp;•
                  เปิดใช้งานฟีเจอร์สที่ทำให้แอปฯคุณมีความเฉพาะตัว เช่น
                  ประวัติการจองและหรือการเล่นกีฬาที่ผ่านมาของคุณ
                  <br />&nbsp;&nbsp;&nbsp;•
                  เพื่อการบริหารจัดการภายในที่จำเป็นในการให้บริการของเรา เช่น
                  แก้ปัญหาบั๊ค (bugs) ในซอฟต์แวร์ และปัญหาด้านการปฏิบัติการ
                  การวิเคราะห์ข้อมูล การทดสอบและวิจัย
                  การติดตามและวิเคราะห์แนวทางใช้งานและกิจกรรม
                  <br />&nbsp;&nbsp;&nbsp;•
                  ทำให้บริการและการสื่อสารของเรามีความเกี่ยวข้องกับคุณมากขึ้น
                  รวมถึงการสร้างโปรไฟล์ลูกค้า
                  การนำเสนอเนื้อหาส่วนบุคคลและการให้คำแนะนำที่เกี่ยวข้องกับคุณ
                  <br />&nbsp;&nbsp;&nbsp;• ตรวจสอบให้แน่ใจว่าเนื้อหาจากแอปฯ และ
                  เว็บไซต์ของเรามีการนำเสนออย่างมีประสิทธิภาพสูงสุดสำหรับคุณ
                  และอุปกรณ์ของคุณเพื่อให้คุณสามารถใช้ประโยชน์จากบริการที่คุณสมัครเป็นสมาชิกได้อย่างเต็มที่
                  <br />&nbsp;&nbsp;&nbsp;•
                  วัดและทำความเข้าใจประสิทธิภาพของการโฆษณาที่ให้บริการกับคุณและผู้อื่นและเพื่อส่งโฆษณาที่
                  เกี่ยวข้องกับคุณไปให้คุณ <br />4.2 ความปลอดภัยและความมั่นคง
                  <br />เราใช้ข้อมูลของคุณเพื่อทำให้แน่ใจถึงความปลอดภัยและความมั่นคงของบริการของเราและผู้ใช้งานทุกคน
                  รวมถึง: <br />&nbsp;&nbsp;&nbsp;• ใช้ข้อมูลอุปกรณ์ ที่ตั้ง
                  ประวัติ การใช้งาน และข้อมูลส่วนบุคคลอื่น เพื่อป้องกัน ตรวจจับ
                  และต่อสู้กับการฉ้อโกง หรือกิจกรรมที่ไม่ปลอดภัย
                  <br />&nbsp;&nbsp;&nbsp;• ตรวจสอบ ป้องกัน
                  และดำเนินคดีตามกฎหมาย <br />4.3 การสนับสนุนลูกค้า
                  <br />เราใช้ข้อมูลส่วนบุคคลเพื่อแก้ปัญหาด้านการสนับสนุนลูกค้าเพื่อประโยชน์สูงสุด
                  ตัวอย่างเช่น <br />&nbsp;&nbsp;&nbsp;•
                  สืบสวนและจัดการความกังวล <br />&nbsp;&nbsp;&nbsp;•
                  ติดตามและพัฒนาการตอบสนองด้านการสนับสนุนลูกค้า
                  <br />&nbsp;&nbsp;&nbsp;• ตอบสนองต่อคำถาม ความคิดเห็น
                  และผลตอบรับ และ <br />&nbsp;&nbsp;&nbsp;•
                  แจ้งคุณเกี่ยวกับขั้นตอนการดำเนินการเพื่อแก้ปัญหาด้านการสนับสนุนลูกค้า
                </span>
              </p>

              <p class="c" style="margin-left: 20px; margin-right: 20px">
                <span style="font-size: 14px">
                  <b>5. การเปิดเผยข้อมูลส่วนบุคคล – Data Disclosure</b><br />
                  5.1 เพื่อให้การดำเนินธุรกิจของเราเป็นไปอย่างราบรื่น
                  หรือเพื่อการปฏิบัติหน้าที่ที่เรามีต่อคุณ
                  เราอาจเปิดเผยข้อมูลส่วนบุคคลของคุณที่คุณให้ไว้แก่เราต่อผู้ให้บริการซึ่งเป็นบุคคลที่สาม
                  ตัวแทน บริษัทในเครือ หรือบริษัทที่เกี่ยวข้อง
                  เพื่อวัตถุประสงค์อย่างใดอย่างหนึ่งหรือหลายอย่างตามที่ระบุหรือได้แจ้งต่อคุณตามการแจ้งวัตถุประสงค์ของการจัดเก็บ
                  นำไปใช้ เปิดเผย และดำเนินการกับข้อมูลส่วนบุคคลแก่คุณ
                  อย่างไรก็ตามการเปิดเผยข้อมูลส่วนบุคคลของคุณจะต้องได้รับความยินยอมจากคุณ
                  ข้อมูลส่วนบุคคลของคุณจะถูกแชร์กับบุคคลที่สามเมื่อ
                  <br />&nbsp;&nbsp;&nbsp;•
                  เราอาจแชร์ข้อมูลกับบุคคลที่สามที่ให้บริการ (เช่น
                  เครือข่ายสังคมออนไลน์)
                  ที่คุณเลือกที่จะเชื่อมโยงกับบริการของเราเท่าที่การแชร์ดังกล่าวจำเป็นเพื่อให้คุณสามารถใช้บริการเหล่านี้ได้
                  <br />
                  5.2
                  นอกจากนี้เราอาจเปิดเผยข้อมูลส่วนบุคคลของคุณต่อเจ้าหน้าที่หรือหน่วยงานของรัฐ
                  เพื่อให้เป็นไปตามกฎหมาย กฎ แนวทาง ระเบียบ
                  หรือระบบการจัดการที่บังคับใช้กับบริษัท
                </span>
              </p>

              

              <p class="c" style="margin-left: 20px; margin-right: 20px"></p>

              <p class="c" style="margin-left: 20px; margin-right: 20px">
                <span style="font-size: 14px">
                  <b
                    >6. การเก็บรักษาข้อมูลส่วนบุคคล – Data Retention and
                    Deletion</b
                  ><br />
                  6.1 เราจะจัดให้มีการรักษาความปลอดภัยที่เหมาะสม
                  เพื่อรับรองแก่คุณว่าข้อมูลส่วนบุคคลของคุณได้รับการคุ้มครองและรักษาความปลอดภัยอย่างเพียงพอ
                  เราจะใช้มาตรการการรักษาความปลอดภัยที่เหมาะสม
                  เพื่อป้องกันการเข้าถึงข้อมูลส่วนบุคคลโดยไม่ได้รับอนุญาต
                  การจัดเก็บ การใช้ การเปิดเผย การคัดลอก การดัดแปลง การรั่วไหล
                  การสูญหาย ความเสียหาย และ/หรือการเปลี่ยนแปลงข้อมูลส่วนบุคคล
                  อย่างไรก็ตาม
                  เราจะไม่รับผิดชอบในกรณีที่บุคคลภายนอกใช้ข้อมูลส่วนบุคคลของคุณโดยไม่ได้รับอนุญาต
                  อันเนื่องมาจากเหตุที่เราไม่สามารถควบคุมได้
                  <br />
                  6.2 เราจะจัดให้มีมาตรการ
                  เพื่อรับรองแก่คุณว่าข้อมูลส่วนบุคคลของคุณที่อยู่ในความครอบครองหรืออยู่ภายใต้การควบคุมของเราจะถูกทำลายและ/หรือถูกทำให้เป็นข้อมูลนิรนามโดยทันทีในระยะเวลาที่เหมาะสม
                  เมื่อ <br />&nbsp;&nbsp;&nbsp;•
                  ข้อมูลส่วนบุคคลที่จัดเก็บไว้ไม่เป็นประโยชน์ต่อวัตถุประสงค์ของการจัดเก็บอีกต่อไป
                  <br />&nbsp;&nbsp;&nbsp;•
                  การเก็บรักษาข้อมูลส่วนบุคคลนั้นไม่จำเป็นต่อวัตถุประสงค์ทางกฎหมายหรือทางธุรกิจอีกต่อไป
                </span>
              </p>

              

              <p class="c" style="margin-left: 20px; margin-right: 20px"></p>

              <p class="c" style="margin-left: 20px; margin-right: 20px">
                <span style="font-size: 14px">
                  <b>
                    7. การโอนข้อมูลส่วนบุคคลระหว่างประเทศ - International
                    Transfer of Data</b
                  ><br />
                  ข้อมูลส่วนบุคคลของคุณอาจถูกโอนจากประเทศ รัฐ และเมือง
                  (“ประเทศของคุณอาศัยอยู่”) ที่คุณอาศัยอยู่ขณะที่ใช้บริการของเรา
                  ไปยังประเทศ รัฐ และเมืองอื่น (“ประเทศอื่น”)
                  คุณเข้าใจและยินยอมให้มีการโอนข้อมูลส่วนบุคคลของคุณออกจากประเทศที่คุณอาศัยอยู่ไปยังประเทศอื่น
                </span>
              </p>

              

              <p class="c" style="margin-left: 20px; margin-right: 20px">
                <span style="font-size: 14px">
                  <b> 8. คุกกี้ – Cookies</b><br />
                  แมทช์เดย์ และบุคคลภายนอกซึ่งเป็นคู่ค้าและ/หรือพันธมิตร
                  ของแมทช์เดย์อาจใช้คุกกี้ (cookies) เว็บบีคอน (web beacon)
                  ป้ายระบุ (tag) คำสั่ง (script) วัตถุใช้ร่วมกันเฉพาะที่ (local
                  shared objects) เช่น เอชทีเอ็มเอล 5 (HTML5) และ แฟลช (Flash)
                  (ซึ่งบางครั้งเรียกว่า “แฟลชคุกกี้ (flash cookies)”)
                  เครื่องมือระบุโฆษณาที่เกี่ยวข้อง (advertising identifiers)
                  (รวมถึงเครื่องมือระบุโฆษณาในโทรศัพท์มือถือ เช่น
                  ไอดีเอฟเอของบริษัทแอปเปิ้ล (Apple’s IDFA)
                  หรือแอดเวอร์ไทซิ่งไอดีของกูเกิ้ล (Google’s Advertising ID)
                  และเทคโนโลยีที่คล้ายคลึงกัน (ต่อไปนี้จะเรียกว่า “คุกกี้”)
                  ในการใช้เว็บไซต์หรือแอปพลิเคชันต่าง ๆ
                  โดยคุกกี้อาจมีตัวระบุพิเศษ และอาศัยอยู่ในคอมพิวเตอร์
                  โทรศัพท์มือถือของคุณ ไปรษณีย์อิเล็กทรอนิกส์ (email)
                  ที่เราส่งให้แก่คุณ และบนหน้าเว็บของเรา ฯลฯ
                  และคุกกี้อาจถ่ายทอดข้อมูลเกี่ยวกับคุณและการใช้บริการของคุณ
                  เช่น ประเภทของเบราว์เซอร์ (browser) ที่คุณใช้ การเลือกคำค้นหา
                  ไอพีแอดเดรส (IP address)
                  ข้อมูลที่เกี่ยวกับโฆษณาซึ่งได้แสดงให้คุณเห็นหรือที่คุณได้คลิกเลือก
                  และวันที่และเวลาของการใช้งาน ทั้งนี้
                  คุกกี้อาจยังคงอยู่และถูกจัดเก็บเฉพาะระหว่างช่วงเวลาใดช่วงเวลาหนึ่งเท่านั้น
                  <br />แมทช์เดย์อาจอนุญาตให้บุคคลภายนอกใช้คุกกี้บนเว็บไซต์และแอปพลิเคชันเพื่อจัดเก็บข้อมูลในลักษณะเดียวกันกับที่แมทช์เดย์จัดเก็บเพื่อประโยชน์แมทช์เดย์เอง
                  บุคคลภายนอกอาจสามารถเชื่อมโยงข้อมูลที่จัดเก็บโดยบุคคลภายนอกเองกับข้อมูลอื่น
                  ๆ
                  ที่มีเกี่ยวกับคุณซึ่งได้มาจากแหล่งข้อมูลอื่นโดยเราอาจไม่จำเป็นต้องเข้าถึงหรือควบคุมคุกกี้ที่ใช้โดยบุคคลภายนอก
                  นอกจากนี้
                  เราอาจเปิดเผยข้อมูลที่ไม่ใช่ข้อมูลที่สามารถระบุตัวบุคคลได้ซึ่งได้มาจากหรือเกี่ยวกับคุณให้แก่บุคคลภายนอก
                  เช่น ข้อมูลของสถานที่ตั้ง เครื่องมือระบุโฆษณาที่เกี่ยวข้อง
                  (advertising identifiers) หรือการเข้ารหัสแบบทางเดียว
                  (cryptographic hash) ของเครื่องมือระบุบัญชีร่วม (เช่น
                  ที่อยู่ไปรษณีย์อิเล็กทรอนิกส์ (email address)
                  เพื่ออำนวยความสะดวกในการแสดงโฆษณาเป้าหมาย
                  <br />หากคุณไม่ประสงค์ให้มีการจัดเก็บข้อมูลส่วนบุคคลผ่านคุกกี้บนเว็บไซต์
                  คุณอาจปิดการใช้งานคุกกี้ได้โดยปรับการตั้งค่าอินเทอร์เน็ตเบราว์เซอร์
                  (internet browser) เพื่อปิด บล๊อค
                  หรือระงับการใช้งานคุกกี้โดยลบประวัติการเยี่ยมชมและลบแคช
                  (cache) จากอินเทอร์เน็ตเบราว์เซอร์ (internet browser) ของคุณ
                  คุณอาจจำกัดการเปิดเผยข้อมูลบางส่วนผ่านการตั้งค่าของโทรศัพท์มือถือของคุณได้
                  หรือโดยการส่งรายละเอียดของคุณผ่านช่องทางนี้ ที่นี่
                </span>
              </p>

              

              <p class="c" style="margin-left: 20px; margin-right: 20px"></p>

              <p class="c" style="margin-left: 20px; margin-right: 20px">
                <span style="font-size: 14px">
                  <b
                    >9. การเปลี่ยนแปลง และปรับปรุงให้เป็นปัจจุบัน – Rights to
                    Update Privacy Policy</b
                  ><br />
                  แมทช์เดย์มีสิทธิที่จะเปลี่ยนแปลง ปรับปรุงให้เป็นปัจจุบัน
                  หรือแก้ไขข้อกำหนดของนโยบายนี้ในเวลาใด ๆ
                  โดยลงประกาศที่ได้รับการปรับปรุงให้เป็นปัจจุบันแล้วลงบนเว็บไซต์และ/หรือแอปฯ
                  หรืออาจส่งประกาศแจ้งเตือนให้คุณทราบโดยตรง เมื่อคุณใช้แอปฯ
                  เว็บไซต์ หรือบริการ ซื้อผลิตภัณฑ์จากแมทช์เดย์ หรือติดต่อ
                  มีส่วนรวมกับแมทช์เดย์ต่อไปภายหลังจากการเปลี่ยนแปลง
                  ปรับปรุงข้อมูลให้เป็นปัจจุบัน หรือแก้ไขนโยบายนี้
                  ให้ถือว่าคุณได้ตกลงให้มีการเปลี่ยนแปลง ปรับปรุงให้เป็นปัจจุบัน
                  หรือแก้ไขดังกล่าวแล้ว
                </span>
              </p>

              

              <p class="c" style="margin-left: 20px; margin-right: 20px"></p>

              <p class="c" style="margin-left: 20px; margin-right: 20px">
                <span style="font-size: 14px">
                  <b
                    >10. สิทธิเกี่ยวกับข้อมูลส่วนบุคคลของท่าน – Rights to Your
                    Personal Information</b
                  ><br />
                  10.1 คุณสามารถขอเข้าถึง ขอรับสำเนาข้อมูลส่วนบุคคลของคุณ
                  หรือขอให้เปิดเผยการได้มาซึ่งข้อมูลส่วนบุคคล ทั้งนี้
                  เราอาจปฏิเสธคำขอของคุณได้ตามที่กฎหมายกำหนดหรือตามคำสั่งศาล
                  <br />10.2
                  คุณสามารถขอแก้ไขหรือเปลี่ยนแปลงข้อมูลส่วนบุคคลของคุณที่ไม่ถูกต้องหรือไม่สมบูรณ์
                  และทำให้ข้อมูลของคุณเป็นปัจจุบันได้ <br />10.3
                  คุณสามารถขอลบหรือทำลายข้อมูลส่วนบุคคลคุณ เว้นแต่เป็นกรณีที่เรา
                  ต้องปฏิบัติตามกฎหมายที่เกี่ยวข้องในการเก็บรักษาข้อมูลดังกล่าว
                </span>
              </p>

              

              <p class="c" style="margin-left: 20px; margin-right: 20px"></p>

              <p class="c" style="margin-left: 20px; margin-right: 20px">
                <span style="font-size: 14px">
                  <b
                    >11. การเปลี่ยนแปลงนโยบายความเป็นส่วนตัว – Notification of
                    Privacy Policy Update</b
                  ><br />
                  11.1 เราอาจอัพเดทนโยบายความเป็นส่วนตัวนี้เป็นครั้งคราว
                  ซึ่งในกรณีนี้เราจะส่งการแจ้งเตือนเพื่อแจ้งให้คุณทราบถึงการเปลี่ยนแปลงและขอให้คุณทราบและยินยอมการเปลี่ยนแปลงดังกล่าว
                  ทั้งนี้คุณมีอิสระที่จะตัดสินว่าจะให้ความยินยอมสำหรับการเปลี่ยนแปลงดังกล่าวหรือปฏิเสธคำขอการเปลี่ยนแปลงของเรา
                  หากการปฏิเสธจะส่งผลต่อบริการของเรา
                  เราจะแจ้งให้คุณทราบเมื่อมีการเปลี่ยนแปลง
                  <br />11.2
                  ในกรณีที่เราคิดว่าเหมาะสมและในกรณีที่เราทำการเปลี่ยนแปลงที่สำคัญในประกาศความเป็นส่วนตัวของ
                  เราจะส่งอีเมลถึงคุณหรือส่งข้อความถึงคุณเพื่อแจ้งให้คุณทราบว่าเราได้อัปเดตประกาศความเป็น
                  ส่วนตัวของเรา
                </span>
              </p>

              

              <p class="c" style="margin-left: 20px; margin-right: 20px">
                <span style="font-size: 14px">
                  <b>11. ติดต่อเรา – Contact Information</b><br />
                  หากคุณมีข้อสงสัยเกี่ยวกับข้อมุลส่วนบุคคลของคุณ
                  โปรดติดต่อเราได้ที่: admin@matchday.co.th
                  <br />ในกรณีที่มีความขัดแย้งระหว่างนโยบายฉบับภาษาอังกฤษและภาษาอื่นๆ
                  ให้ถือเอานโยบายฉบับภาษาไทยเป็นสำคัญ
                </span>
              </p>
              <br />
              <!-- <div class="text-center">
                <span style="font-size: 24px">
                  &nbsp; &nbsp;<b>
                    Privacy Policy MATCHDAY - แอพจองสนาม</b
                  ></span
                >
              </div> -->
              <br />
              <br />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    document.getElementById("privacy").scrollIntoView();
  }
};
</script>

<style>
.divider {
  height: 1px;
  width: 95%;
  border-bottom: 1px #707070 solid;
}
</style>
